import { Injectable } from '@angular/core';
import { AngularFireAuth } from 'angularfire2/auth';
import * as firebase from 'firebase';
import { Observable, Subject, BehaviorSubject } from 'rxjs';
import { from } from 'rxjs';
import { LoginResult } from './LoginResult';
import { AngularFireDatabase } from 'angularfire2/database';
import { IUserPlan } from '../shared/common.models';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  userInfo = new BehaviorSubject<IUserPlan>({
    plan: 'basic',
    name: 'GWEB'
  });

  authState: firebase.User;
  constructor(public afAuth: AngularFireAuth, 
    private afs: AngularFireDatabase) {
    afAuth.authState.subscribe(data => {

      this.authState = data;
      this.afs.database.ref(this.ProviderName + '/info').once('value', snap => {
        let info = snap.val();

        if (!info)
          info = { plan: 'basic' }

        if (!info.plan)
          info.plan = 'basic';

        this.userInfo.next(info);
      });

      this._setLoginedTenant();
    });
  }

  // get getUserPlan(): string {
  //   return this.userInfo.plan;
  // }

  get IsAuthenticated(): boolean {
    return this.authState !== null && this.authState !== undefined;
  }

  get CurrentUserId(): string {
    return this.IsAuthenticated ? this.authState.uid : null;
  }

  // get CurrentUserName(): string {
  //   // if (this.userInfo && this.userInfo.name)
  //   //   return this.userInfo.name;
  //   return this.IsAuthenticated ? (this.authState.displayName ? this.authState.displayName : this.authState.email) : "Unknown";
  // }

  private getTenantName() {
    if (this.IsAuthenticated) {
      var userArr = this.authState.email.split('@');
      if (userArr.length > 0)
        return userArr[0];
    }
    return '';
  }

  get ProviderName() {
    if (this.IsAuthenticated) {
      return this.getTenantName();
    } else {
      return this._lastLoginedTenant();
    }
  }

  private _setLoginedTenant() {
    localStorage.setItem('gweb-tenant', this.getTenantName());
  }

  private _lastLoginedTenant() {
    if (localStorage.getItem('gweb-tenant'))
      return localStorage.getItem('gweb-tenant');
    return "";
  }

  loginGoogle() {
    // this.afAuth.auth.signInWithPopup(new firebase.auth.GithubAuthProvider());
    this.afAuth.auth.signInWithPopup(new firebase.auth.GoogleAuthProvider());
  }

  loginGithub() {
    this.afAuth.auth.signInWithPopup(new firebase.auth.GithubAuthProvider());
  }

  loginUser(username: string, password: string): Promise<LoginResult> {
    return new Promise(
      function (resolve, reject) {
        this.afAuth.auth.signInWithEmailAndPassword(username, password)
          .then((user) => {
            //console.log(user);
            const result: LoginResult = { error: "", success: true };
            resolve(result);
          }).catch((res) => {
            //console.log(res);
            const result: LoginResult = { error: res.message, success: false };
            resolve(result);
          })
      }.bind(this)
    );

  }

  logout(): Promise<void> {
    localStorage.removeItem('gweb-tenant');
    return this.afAuth.auth.signOut();
  }

  reset() {

  }


}
