import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  template: `
    <app-nav></app-nav>
  `,
  styles: []
})

// <app-navbar></app-navbar>
// <router-outlet style="margin-bottom: 75px;display: block;"></router-outlet>
// <app-footer></app-footer>

export class AppComponent {
  title = 'GWEB Portal';

  constructor(translate: TranslateService) {
    translate.setDefaultLang('tr');
    let currLang = window.navigator['userLanguage'] || window.navigator.language;


    if (localStorage.getItem("lang")) {
      currLang = localStorage.getItem("lang");
    }

    if (currLang) {
      if (currLang == 'tr-TR')
        currLang = 'tr'
      if (currLang == 'en-US')
        currLang = 'en'
    } else {
      currLang = 'tr'
    }

    translate.use(currLang);

    localStorage.setItem("lang", currLang);
  }

}
