import { Injectable } from '@angular/core';
import { AngularFireDatabase, AngularFireList } from 'angularfire2/database';
import { Observable } from 'rxjs';
import { INotification } from '../common.models';
import { AuthService } from 'src/app/core/auth.service';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class NtfService {

    private COLLECTION_NAME = "/notification";
    private LIMIT = 50;

    setLimit(limit: number) {
        this.LIMIT = limit
    }

    // list$: AngularFireList<INotification> = {} as AngularFireList<INotification>;
    private list$: AngularFireList<INotification>;
    private listLast$: AngularFireList<INotification>;
    provider: string;

    constructor(private afs: AngularFireDatabase, public authService: AuthService) {
        this.provider = this.authService.ProviderName;
        this.list$ = this.afs.list<INotification>(this.provider + this.COLLECTION_NAME, (ref) => {
            return ref.limitToLast(this.LIMIT);
        });

        this.listLast$ = this.afs.list<INotification>(this.provider + this.COLLECTION_NAME, (ref) => {
            return ref.limitToLast(3);
        });
    }

    getNotifications(provider?: string): Observable<INotification[]> {
        return this.list$.snapshotChanges()
            .pipe(map((list: any[]) => list.map(item => {
                return { id: item.key, ...item.payload.val() }
            }
            )));
    }

    getLastNotifications(): Observable<INotification[]> {
        return this.listLast$.snapshotChanges()
            .pipe(map((list: any[]) => list.map(item => {
                return { id: item.key, ...item.payload.val() }
            }
            )));
    }

    addNotification(ntf: INotification) {
        if (!ntf.date)
            ntf.date = Date.now();

        ntf.received = 0;
        ntf.opened = 0;
        ntf.dismissed = 0;
        return this.list$.push(ntf);
    }

    addScheduledNotification(ntf: INotification) {
        return this.addNotification(ntf);
    }

    delete(id: string): Promise<any> {
        return this.list$.remove(id);
    }

}
