import { Injectable } from "@angular/core";
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate } from '@angular/router';
import { AuthService } from 'src/app/core/auth.service';
import { map, catchError, switchMap } from 'rxjs/operators';
import { of } from 'rxjs/internal/observable/of';
import { Observable } from 'rxjs/internal/Observable';
import { IUserPlan } from '../common.models';
import { BehaviorSubject } from 'rxjs';



@Injectable({
    providedIn: 'root'
})
export class AdminGuard implements CanActivate {
    path: ActivatedRouteSnapshot[];
    route: ActivatedRouteSnapshot;

    constructor(public authService: AuthService, public router: Router) { }

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        if (!this.authService.IsAuthenticated) {
            this.router.navigate(['/admin/login']);
            return of(false);
        }
        return of(true);

        // this.authService.afAuth.user.pipe(
        //     switchMap(a=>{

        //     })
        // )

        // this.authService.afAuth.user.subscribe(user => {
        //     if (user && user.uid) {
        //         return true;
        //     } else {
        //         this.router.navigate(['/admin/login']);
        //         return of(false);
        //     }
        // });
    }
}

@Injectable({
    providedIn: 'root'
})
export class FeatureGuard implements CanActivate {
    private path: ActivatedRouteSnapshot[];
    private route: ActivatedRouteSnapshot;


    fatureControls: any = {
        'mobile-menu': new BehaviorSubject<boolean>(true),
        'settings': new BehaviorSubject<boolean>(true),
        'notification': new BehaviorSubject<boolean>(true),
    }

    private allFeatures: string[] = ['notification', 'mobile-menu', 'settings'];

    private basicPlanFeatures: string[] = ['notification'];
    private plusPlanFeatures: string[] = [...this.basicPlanFeatures, 'mobile-menu'];
    private premiumPlanFeatures: string[] = [...this.plusPlanFeatures, 'settings'];

    private currentUserFeatures: string[] = this.basicPlanFeatures;
    private isPlanLoaded: boolean = false;

    constructor(public authService: AuthService, public router: Router) {
        this.authService.userInfo.subscribe(info => {
            if (info.name == "GWEB")
                return;

            let userPlan = info.plan;
            if (!userPlan || userPlan == 'basic')
                this.currentUserFeatures = this.basicPlanFeatures;

            if (userPlan == 'plus')
                this.currentUserFeatures = this.plusPlanFeatures;

            if (userPlan == 'premium')
                this.currentUserFeatures = this.premiumPlanFeatures;

            this.isPlanLoaded = true;

            this.allFeatures.forEach(val => {
                if (this.canLoadModule(val) == false)
                    (this.fatureControls[val] as BehaviorSubject<boolean>).next(false);
            })
        });
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        let feature = route.data.feature;
        console.log("checking feature : " + feature);

        if (!feature) {
            return of(false);
        }

        if (this.currentUserFeatures) {
            let result = this.currentUserFeatures.indexOf(feature) > -1;
            return of(result);
        } else {
            return this.authService.userInfo
                .pipe(
                    map(info => {
                        let userPlan = info.plan;
                        if (info.name == "GWEB")
                            return false;

                        if (!userPlan || userPlan == 'basic')
                            this.currentUserFeatures = this.basicPlanFeatures;

                        if (userPlan == 'plus')
                            this.currentUserFeatures = this.plusPlanFeatures;

                        if (userPlan == 'premium')
                            this.currentUserFeatures = this.premiumPlanFeatures;

                        this.isPlanLoaded = true;

                        return this.canLoadModule(feature, info);
                    }),
                    catchError(error => of(false))
                );
        }

        // return true;

        //     return this.fooService.apiGet('my/url')
        //    .pipe(
        //      map(response => response.status === 'success'),
        //      catchError(error => of(false))
        //    );
    }

    canLoadModule(feature: string, info?: IUserPlan): boolean {
        // if (info && info.name == "GWEB")
        //     return false;

        // let userPlan = info.plan;
        // if (!userPlan || userPlan == 'basic')
        //     this.currentUserFeatures = this.basicPlanFeatures;

        // if (userPlan == 'plus')
        //     this.currentUserFeatures = this.plusPlanFeatures;

        // if (userPlan == 'premium')
        //     this.currentUserFeatures = this.premiumPlanFeatures;
        // this.isPlanLoaded = true;

        let result = this.currentUserFeatures.indexOf(feature) > -1;
        return result;
    }

    canLoadModule2(feature: string, planName?: string): Observable<boolean> {

        return this.fatureControls[feature] as BehaviorSubject<boolean>;

        // if (this.isPlanLoaded) {
        //     let result = this.currentUserFeatures.indexOf(feature) > -1;
        //     return of(result);
        // } else {
        //     return this.authService.userInfo
        //         .pipe(
        //             map(info => {
        //                 let userPlan = info.plan;
        //                 return this.canLoadModule(feature, info);
        //             }),
        //             catchError(error => of(false))
        //         );
        // }
    }
}
