import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdminGuard, FeatureGuard } from './shared/guards/admin.guard';


const routes: Routes = [
  { path: '', redirectTo: '/admin', pathMatch: 'full' },
  {
    path: 'admin',
    loadChildren: './admin/admin.module#AdminModule'
  },
  {
    path: 'ntf',
    loadChildren: './admin/notifications/notifications.module#NotificationsModule',
    canActivate: [AdminGuard],
    data: { feature: 'notification' }
  },
  {
    path: 'mobile-menu',
    loadChildren: './admin/mobile-menu/mobile-menu.module#MobileMenuModule',
    canActivate: [AdminGuard],
    data: { feature: 'mobile-menu' }
  },
  {
    path: 'welcome-screen',
    loadChildren: './admin/welcome-screen/welcome-screen.module#WelcomeScreenModule',
    canActivate: [AdminGuard],
    data: { feature: 'welcome' }
  },
  {
    path: 'settings',
    loadChildren: './admin/settings/settings.module#SettingsModule',
    canActivate: [AdminGuard],
    data: { feature: 'settings' }
  },
  {
    path: 'contact',
    loadChildren: './pages/contact/contact.module#ContactModule',
    canActivate: [],
    data: { feature: 'settings' }
  },
  { path: '**', redirectTo: '' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
