import { Injectable } from '@angular/core';
import { AngularFireList, AngularFireDatabase } from 'angularfire2/database';
import { AuthService } from 'src/app/core/auth.service';
import { INotificationChannel } from 'src/app/shared/common.models';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class NtfChannelsService {

  private COLLECTION_NAME = "info/channels";
  private LIMIT = 100;

  list$: AngularFireList<INotificationChannel>;
  provider: string;

  constructor(private afs: AngularFireDatabase, public authService: AuthService) {
    this.provider = this.authService.ProviderName;
    this.list$ = this.afs.list<INotificationChannel>(this.authService.ProviderName + '/' + this.COLLECTION_NAME, (ref) => {
      return ref;
    });
  }

  getList(): Observable<INotificationChannel[]> {
    return this.list$.snapshotChanges()
      .pipe(map((list: any[]) => list.map(item => {
        return { id: item.key, ...item.payload.val() }
      }
      )));
  }

  addItem(ntf: INotificationChannel): Promise<any> {
    if (!ntf)
      return Promise.reject(new Error('channel is undefined or null'));;

    if (!ntf.id)
      ntf.id = 'ch-' + Date.now();

    if (!ntf.name)
      return Promise.reject(new Error('name is not defined for channel'));

    var obj = {};
    obj[ntf.id] = ntf;
    return this.afs.database.ref(this.authService.ProviderName + '/' + this.COLLECTION_NAME).update(obj)
  }

  deleteItem(id: string): Promise<any> {
    if (!id) {
      return Promise.reject(new Error('id is not defined!'));
    }

    if (this.authService.ProviderName && this.COLLECTION_NAME && id)
      return this.afs.database
        .ref(this.authService.ProviderName + '/' + this.COLLECTION_NAME + '/' + id)
        .remove();
    else
      return this.list$.remove(id);
  }

}
