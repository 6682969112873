
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import Swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';
import { INotification } from 'src/app/shared/common.models';
import { BaseComponent } from '../base.component';
import { Notification, Subscription } from 'rxjs';
import { NtfService } from '../../services/ntf.service';

@Component({
  selector: 'app-last-notifications',
  templateUrl: './last-notifications.component.html',
  styleUrls: ['./last-notifications.component.scss']
})
export class LastNotificationsComponent implements OnInit {

  notificationList: any[] = [];
  _notificationSubsription: Subscription;
  isLoading: boolean = true;

  constructor(public translateService: TranslateService,
    private ntfService: NtfService) {

  }

  ngOnInit() {
    this._notificationSubsription = this.ntfService.getLastNotifications().subscribe(res => {
      this.notificationList = res.reverse();
      this.isLoading = false;
    });
  }

  ngOnDestroy() {
    if (this._notificationSubsription)
      this._notificationSubsription.unsubscribe();
  }

}
