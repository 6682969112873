import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '../material.module';
import { NavbarComponent } from './navbar/navbar.component';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FooterComponent } from './footer/footer.component';
import { AdminGuard, FeatureGuard } from './guards/admin.guard';
import { FeatureLockCheckDirective, LockComponent } from './directives/feature-lock-check.directive';
import { ContactFormComponent } from './components/contact-form/contact-form.component';
import { TranslateModule } from '@ngx-translate/core';
import { NavComponent } from './nav/nav.component';
import { NavigationBarComponent } from './navigation-bar/navigation-bar.component';
import { QuickSendNotificationComponent } from './components/quick-send-notification/quick-send-notification.component';
import { LastNotificationsComponent } from './components/last-notifications/last-notifications.component';

@NgModule({
  declarations: [
    NavbarComponent,
    FooterComponent,
    FeatureLockCheckDirective,
    LockComponent,
    ContactFormComponent,
    NavComponent,
    NavigationBarComponent,
    QuickSendNotificationComponent,
    LastNotificationsComponent,
    LastNotificationsComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    MaterialModule,
    RouterModule,
    ReactiveFormsModule,
    TranslateModule,
  ],
  exports: [
    CommonModule,
    FormsModule,
    FooterComponent,
    MaterialModule,
    NavbarComponent,
    RouterModule,
    ReactiveFormsModule,
    FeatureLockCheckDirective,
    ContactFormComponent,
    TranslateModule,
    NavComponent,
    QuickSendNotificationComponent,
    LastNotificationsComponent
  ],
  providers: [
    AdminGuard,
    FeatureGuard
  ],
  entryComponents: [
    LockComponent,
    ContactFormComponent
  ]
})
export class SharedModule { }
