import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/core/auth.service';
import { User } from 'firebase';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';



@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {

  //user : User;
  constructor(public authService: AuthService,
    private router: Router,
    private translate: TranslateService) { }

  currentLang: string = "";

  ngOnInit() {
    this.currentLang = this.translate.currentLang.toUpperCase();
    this.translate.onLangChange.subscribe(() => {
      this.currentLang = this.translate.currentLang.toUpperCase();
    })
    //this.auth.logout()
    // this.auth.afAuth.authState.subscribe(user => {
    //   this.user = user;
    //   console.log(user);
    // })
    //console.log(this.auth.afAuth.authState);
  }

  changeLang(lang: string) {
    this.translate.use(lang);
    localStorage.setItem("lang", lang);
  }

  logout() {
    this.authService.logout().then(() => {
      this.authService.reset();
      this.router.navigate(['/admin/login'])
    });
  }

  get appname() {
    return this.authService.ProviderName;
  }

}
