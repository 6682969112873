import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material';
import { AuthService } from 'src/app/core/auth.service';
import { IContactMessage } from '../../common.models';

@Component({
  selector: 'app-contact-form',
  templateUrl: './contact-form.component.html',
  styleUrls: ['./contact-form.component.css']
})
export class ContactFormComponent implements OnInit {

  formGroup: FormGroup;
  constructor(public dialogRef: MatDialogRef<ContactFormComponent>,
    private authService: AuthService,
    private formBuilder: FormBuilder) { }

  ngOnInit() {
    this.createForm();
  }

  createForm() {
    this.formGroup = this.formBuilder.group({
      'mailTel': [null, Validators.required],
      'message': [null, Validators.required]
    });
  }

  closeForm() {
    this.dialogRef.close();
  }

  onSubmit(formData) {

    let data = formData as IContactMessage;
    data.provider = this.authService.ProviderName;
    data.date = new Date();

    console.log(data);

    this.dialogRef.close();
  }

}
