import { Injectable } from '@angular/core';
import { AuthService } from 'src/app/core/auth.service';
import { AngularFireDatabase } from 'angularfire2/database';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FeaturesService {

  FEATURES = {
    apk: {
      _self: false,
      "appName": false,
      "download": false
    },
    custom: {
      "countdown": false
    },
    menu: {
      _self: true,
      "footer": false,
      "menuList": true
    },
    notification: {
      _self: true,
      "scheduled": false
    },
    settings: {
      _self: true,
      "splash": false,
      "urlChange": false
    },
    welcome: {
      _self: false
    }
  }

  public featureLoaded: BehaviorSubject<any> = new BehaviorSubject<any>(this.FEATURES);

  constructor(private afs: AngularFireDatabase,
    public authService: AuthService) {
    let provider = this.authService.ProviderName;

    this.afs.object<any>(provider + '/features').valueChanges().subscribe(res => {
      // console.log(res);
      if (res) {
        this.FEATURES = res;
        this.featureLoaded.next(this.FEATURES);
      }
    });
  }

  canLoad(featuePath: string): boolean {
    if (this.FEATURES[featuePath] == true)
      return true;

    if (this.FEATURES[featuePath] == false)
      return false;

    if (featuePath.indexOf(".") == -1) {
      featuePath = featuePath + "._self";
      if (this.FEATURES[featuePath] && this.FEATURES[featuePath]._self == true)
        return true;
    }

    if (featuePath.endsWith("._self") && featuePath.substr(0, featuePath.length - 6)) {
      featuePath = featuePath.substr(0, featuePath.length - 6)
      if (this.FEATURES[featuePath] && this.FEATURES[featuePath]._self == true)
        return true;

      if (this.FEATURES[featuePath] && this.FEATURES[featuePath]._self == false)
        return false;
    }

    return this.checkProperty(this.FEATURES, featuePath);
  }

  private checkProperty(DATA: any, path: string): boolean {
    if (!path)
      return false;

    if (path.indexOf(".") == -1) {
      return DATA[path] == true;
    }

    let part = path.split(".");
    let first = part[0];
    let right = path.substr(path.indexOf(".") + 1)

    return this.checkProperty(DATA[first], right);
  }


}
