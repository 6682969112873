import { Component, EventEmitter, Output, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { AuthService } from 'src/app/core/auth.service';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'crm-navigation-bar',
  templateUrl: './navigation-bar.component.html',
  styleUrls: ['./navigation-bar.component.scss']
})
export class NavigationBarComponent implements OnInit {

  @Output() toggleSidenav = new EventEmitter<void>();

  private returnUrl = '/';
  currentLang: string = "";

  constructor(public authService: AuthService,
    private translate: TranslateService,
    private router: Router) {

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.returnUrl = event.url;
        console.info('NavigationBarComponent returnUrl: ' + this.returnUrl);
      }
    });

  }

  ngOnInit(): void {
    this.currentLang = this.translate.currentLang.toUpperCase();
    this.translate.onLangChange.subscribe(() => {
      this.currentLang = this.translate.currentLang.toUpperCase();
    })
  }

  public onProfile() {
    this.router.navigate(['users/profile']);
  }

  public logout() {
    this.authService.logout().then(()=>{
      this.router.navigate(['admin/login']);
      window.location.reload();
    });
  }

  changeLang(lang: string) {
    this.translate.use(lang);
    localStorage.setItem("lang", lang);
  }

  get appname() {
    return this.authService.ProviderName;
  }

}
