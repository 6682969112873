import { Directive, ElementRef, Input, Renderer, Renderer2, Component, ComponentRef, ComponentFactoryResolver, Injector, ApplicationRef, EmbeddedViewRef } from '@angular/core';
import { FeatureGuard } from '../guards/admin.guard';
import Swal from 'sweetalert2';
import { BehaviorSubject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material';
import { ContactFormComponent } from '../components/contact-form/contact-form.component';
import { Router } from '@angular/router';
import { BaseComponent } from '../components/base.component';
import { FeaturesService } from '../services/features.service';

@Component({
  styles: [`
  .df-icon {
    position: absolute;
    right: 0;
    top: 0;
    color: white;
  }
`],
  template: `
    <div class="df-icon"><mat-icon aria-hidden="false">lock</mat-icon></div>
  `
})
export class LockComponent {
}

@Directive({
  selector: '[appFeatureLockCheck]'
})
export class FeatureLockCheckDirective extends BaseComponent {

  public TRANSLATIONS: any = {
    CANCEL: "",
    LOCKED_FEATURE_1: "",
    CONTANCT: ""
  }

  @Input() feature: string;
  @Input() defaultHide: string;

  lockDiv = '<div class="df-icon"><mat-icon aria-hidden="false">lock</mat-icon></div>'

  private toastContainerElement: HTMLElement;
  private toastContainerRef: ComponentRef<LockComponent>;

  private isLocked: boolean = false;
  private isHidden: boolean = false;
  private iconAdded: boolean = false;

  constructor(public translateService: TranslateService,
    private el: ElementRef,
    private renderer: Renderer2,
    private componentFactoryResolver: ComponentFactoryResolver,
    private injector: Injector,
    private appRef: ApplicationRef,
    private guardService: FeatureGuard,
    public dialog: MatDialog,
    private router: Router,
    private featureService: FeaturesService) {
    super(translateService);
  }

  ngOnInit() {
    super.init();

    this.featureService.featureLoaded.subscribe(features => {
      if (this.featureService.canLoad(this.feature)) {
        if (this.isLocked) {
          //TODO - remove lock

          this.renderer.removeChild(this.el.nativeElement, this.toastContainerElement);
          this.renderer.removeClass(this.el.nativeElement, 'disabled-feature');
          this.renderer.removeAttribute(this.el.nativeElement, "disabled");
          this.isLocked = false;

          this.renderer.removeClass(this.el.nativeElement, 'hidden-feature');
          this.isHidden = false;
        }

        return;
      }

      this.toastContainerRef = this.createComponentRef(LockComponent);
      this.toastContainerElement = this.getDomElementFromComponentRef(this.toastContainerRef);

      this.renderer.listen(this.el.nativeElement, 'click', this.lockedFatureClicked.bind(this))
      this.renderer.addClass(this.el.nativeElement, 'disabled-feature');

      if (this.el.nativeElement.localName != 'button' && this.iconAdded == false){
        this.renderer.appendChild(this.el.nativeElement, this.toastContainerElement);
        this.iconAdded = true;
      }
        
      this.renderer.setAttribute(this.el.nativeElement, "disabled", "disabled");
      this.isLocked = true;

      // if (this.defaultHide == "true") {
      //   this.renderer.addClass(this.el.nativeElement, 'hidden-feature');
      //   this.isHidden = true;
      //   this.renderer.removeChild(this.el.nativeElement.parent, this.el.nativeElement);
      // }
    });

  }

  lockedFatureClicked($event) {
    if (this.isLocked == false)
      return;
    if (!this.guardService.canLoadModule(this.feature)) {
      $event.stopPropagation();
      $event.preventDefault();

      // setTimeout(() => { this.openContact(); }, 500);
      // return;
      // do something
      // alert("Bu özellik için iletişime geçin! " + this.feature);
      Swal.fire({
        title: this.TRANSLATIONS.LOCKED_FEATURE_1,
        type: 'warning',
        showCancelButton: true,
        showCloseButton: true,
        cancelButtonText: this.TRANSLATIONS.CANCEL,
        confirmButtonText: this.TRANSLATIONS.CONTANCT,
      }).then((result) => {
        if (result && result.value) {
          // this.router.navigate(['/contact'])
          setTimeout(() => { this.openContact(); }, 500);
        }
      })

      return;
    }
  }

  createComponentRef(component: any): ComponentRef<any> {
    const componentRef = this.componentFactoryResolver
      .resolveComponentFactory(component)
      .create(this.injector);
    this.appRef.attachView(componentRef.hostView);
    return componentRef;
  }

  getDomElementFromComponentRef(componentRef: ComponentRef<any>): HTMLElement {
    return (componentRef.hostView as EmbeddedViewRef<any>)
      .rootNodes[0] as HTMLElement;
  }

  addChild(child: HTMLElement, parent: HTMLElement = document.body) {
    parent.appendChild(child);
  }

  removeChild(child: HTMLElement, parent: HTMLElement = document.body) {
    parent.removeChild(child);
  }

  openContact() {
    console.log("Contact will be open!");
    const dialogRef = this.dialog.open(ContactFormComponent, {
      width: '600px',
      data: {}
    });
  }

}
