import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

export abstract class BaseComponent {

    // public abstract TRANSLATIONS: any;
    public abstract TRANSLATIONS: any;
    private _translationKeys: string[] = [];

    private _subTrans: Subscription;
    private _subLangChanges: Subscription;

    constructor(public translateService: TranslateService, translations: any = null) {
    }

    /**
     * MUST call in in ngOnInit to set TRANSLATIONS object
     * usage:  super.init();
     */
    public init() {

        if (Array.isArray(this.TRANSLATIONS))
            this._translationKeys = this.TRANSLATIONS;
        else
            this._translationKeys = Object.keys(this.TRANSLATIONS);

        this._subTrans = this.translateService.get(this._translationKeys).subscribe(res => {
            this.TRANSLATIONS = res;
        });

        this._subLangChanges = this.translateService.onLangChange.subscribe(gen => {
            this._subTrans = this.translateService.get(this._translationKeys).subscribe(res => {
                this.TRANSLATIONS = res;
            });
        })
    }

    public destroy() {
        if (this._subTrans)
            this._subTrans.unsubscribe();

        if (this._subLangChanges)
            this._subLangChanges.unsubscribe();
    }
}