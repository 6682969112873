import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material';
import { AuthService } from 'src/app/core/auth.service';
import { SidenavService } from '../services/sidenav.service';
import { Subscription } from 'rxjs';

interface SideNavRoute {
  icon?: string;
  route?: string;
  title?: string;
}

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss']
})
export class NavComponent implements OnInit {

  @ViewChild('commandbarSidenav', { static: true })
  public sidenav: MatSidenav;

  public myWorkRoutes: SideNavRoute[];
  public settignsRoutes: SideNavRoute[];

  // public toolPaletteItems: ToolPaletteItem[];
  protected subscription: Subscription;

  constructor(
    private authService: AuthService,
    private commandBarSidenavService: SidenavService,
  ) { }

  ngOnInit() {
    //todo - move to config file
    this.myWorkRoutes = [{
      icon: "notifications",
      route: "/ntf",
      title: 'NTF.NOTIFICATIONS'
    }, {
      icon: "list",
      route: "/mobile-menu",
      title: 'MENU.MOBILE_MENU'
    },
    {
      icon: "view_module",
      route: "/welcome-screen",
      title: 'WELCOME.SCREEN'
    }];

    this.settignsRoutes = [{
      icon: "build",
      route: "/settings",
      title: 'SETTINGS.SETTINGS'
    }];

    console.log('NavComponent: ngOnInit()');

    this.commandBarSidenavService.setSidenav(this.sidenav);

    this.loadNavListItems();

    this.subscribe();
  }

  public isAuthenticated() {
    return this.authService.IsAuthenticated;
  }

  async loadNavListItems() {
    // this.myWorkRoutes = await this.configService.get('my-work-routes');
    // this.myWorkRoutes.forEach(route => {
    //   this.translate.get(route.title).subscribe(value => {
    //     route.title = value;
    //   });
    // });

    // this.customerRoutes = await this.configService.get('customer-routes');
    // this.customerRoutes.forEach(route => {
    //   this.translate.get(route.title).subscribe(value => {
    //     route.title = value;
    //   });
    // });
  }

  protected subscribe() {
    console.info('NavComponent: subscribe()');
    // this.subscription = this.dashboardService.getToolPaletteItems().subscribe(data => {
    //   this.toolPaletteItems = data;
    // });
  }

  protected unsubscribe() {
    console.info('DashboardComponent: unsubscribe()');
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }


  public ngOnDestroy() {
    console.info('NavComponent: ngOnDestroy()');
    this.unsubscribe();
  }

}
