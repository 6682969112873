import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatFormFieldModule } from '@angular/material/form-field';
import {
  MatInputModule, MatIconModule,
  MatDialogModule, MatDatepickerModule,
  MatNativeDateModule,
  MatGridListModule,
  MatTableModule,
  MatListModule,
  MatTabsModule,
  MatTooltipModule,
  MatCheckboxModule,
  MatExpansionModule,
  MatRippleModule,
  MatBadgeModule,
  MatChipsModule,
  MatMenuModule,
  MatSidenavModule
} from '@angular/material';
import { MatSelectModule } from '@angular/material/select';
import { MatCardModule } from '@angular/material/card';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatDividerModule } from '@angular/material/divider';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MaterialFileInputModule } from 'ngx-material-file-input';
import { FlexLayoutModule } from '@angular/flex-layout';

@NgModule({
  declarations: [],
  imports: [
    MatButtonModule,
    MatCardModule,
    MatDividerModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatProgressBarModule,
    MatSnackBarModule,
    MatToolbarModule,
    MatDialogModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSelectModule,
    MatGridListModule,
    MatListModule,
    MatTableModule,
    MatTabsModule,
    MatTooltipModule,
    MatCheckboxModule,
    MatExpansionModule,
    DragDropModule,
    MatRippleModule,
    MatBadgeModule,
    MatChipsModule,
    MatMenuModule,
    NgxMaterialTimepickerModule,
    MaterialFileInputModule,
    MatSidenavModule,
    FlexLayoutModule
  ],
  exports: [
    MatButtonModule,
    MatCardModule,
    MatDividerModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatProgressBarModule,
    MatSnackBarModule,
    MatToolbarModule,
    MatDialogModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSelectModule,
    MatGridListModule,
    MatListModule,
    MatTableModule,
    MatTabsModule,
    MatTooltipModule,
    MatCheckboxModule,
    MatExpansionModule,
    DragDropModule,
    MatRippleModule,
    MatBadgeModule,
    MatChipsModule,
    MatMenuModule,
    NgxMaterialTimepickerModule,
    MaterialFileInputModule,
    MatSidenavModule,
    FlexLayoutModule
  ]
})
export class MaterialModule { }
