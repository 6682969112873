import { Component, OnInit, LOCALE_ID, Inject, Output, EventEmitter } from '@angular/core';
import { MatDialogRef, MatSnackBar, MAT_DIALOG_DATA } from '@angular/material';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NtfService } from 'src/app/shared/services/ntf.service';
import { INotification, INotificationChannel } from 'src/app/shared/common.models';
import { BaseComponent } from 'src/app/shared/components/base.component';
import { TranslateService } from '@ngx-translate/core';
import { NtfChannelsService } from '../../services/ntf-channels.service';

@Component({
  selector: 'app-quick-send-notification',
  templateUrl: './quick-send-notification.component.html',
  styleUrls: ['./quick-send-notification.component.scss']
})
export class QuickSendNotificationComponent extends BaseComponent implements OnInit {

  @Output() onSent: EventEmitter<void> = new EventEmitter<void>();;

  public TRANSLATIONS: any = {
    AN_ERROR_OCCURRED: "",
    OK: "",
    "NTF.SENT_OK": ""
  };

  channels: INotificationChannel[] = [];
  prioriteis: any[] = [{ value: "high", name: "Yüksek" }, { value: "normal", name: "Normal" }]
  formGroup: FormGroup;
  formData: INotification;
  editNotification: INotification;

  isScheduled: boolean = false;

  constructor(public translateService: TranslateService,
    private formBuilder: FormBuilder,
    private _snackBar: MatSnackBar,
    private ntfService: NtfService,
    private channelService: NtfChannelsService) {
    super(translateService);
  }

  ngOnInit() {
    super.init();
    this.createForm();

    this.channelService.getList().subscribe(res => {
      this.channels = res;
      if (this.channels && this.channels.length > 0)
        this.formGroup.controls["channelId"].setValue(this.channels[0].id);

      if (this.editNotification) {
        let channelId = this.getChannelId(this.editNotification.channelName);
        this.formGroup.patchValue({ channelId: channelId });
      }
    })
  }

  createForm() {
    if (this.isScheduled) {
      this.formGroup = this.formBuilder.group({
        'scheduleDay': [null, [Validators.required]],
        'scheduleTime': [null, [Validators.required]],
        'title': [null, [Validators.required, Validators.maxLength(60), Validators.minLength(3)]],
        'body': [null, [Validators.required, Validators.maxLength(300), Validators.minLength(15)]],
        'url': [null],
        'channelId': [null],
      });
    } else {
      this.formGroup = this.formBuilder.group({
        'title': [null, [Validators.required, Validators.maxLength(60), Validators.minLength(3)]],
        'body': [null, [Validators.required, Validators.maxLength(300), Validators.minLength(15)]],
        'url': [null],
        'channelId': [null],
      });
    }

    if (this.editNotification) {
      this.formGroup.patchValue(this.editNotification);

      if (this.channels && this.channels.length > 0) {
        let channelId = this.getChannelId(this.editNotification.channelName);
        this.formGroup.patchValue({ channelId: channelId });
      }

    }

  }

  clear() {
    this.formGroup.reset();
  }

  isSubmitting: boolean = false;
  onSubmit(formData) {
    this.isSubmitting = true;
    console.log(formData);
    this.formData = formData as INotification;

    this.formData.priority = "high";
    this.formData.date = Date.now();
    this.formData.send = false;

    this.formData.channelName = this.getChannelName(this.formData.channelId);


    this.formData.isScheduled = this.isScheduled;
    if (this.isScheduled) {
      //TODO
    }

    this.ntfService.addNotification(this.formData).then(res => {
      this._snackBar.open(this.TRANSLATIONS["NTF.SENT_OK"], this.TRANSLATIONS.OK, {
        duration: 2000,
      });

      this.formGroup.reset();
      this.onSent.emit();
    }).catch(err => {
      console.log(err);
      this._snackBar.open(this.TRANSLATIONS.AN_ERROR_OCCURRED, this.TRANSLATIONS.OK, {
        duration: 3000,
      });
    }).finally(() => {
      this.isSubmitting = false;
    });
  }

  private getChannelName(id: string) {
    let channel: INotificationChannel = this.channels.find(s => s.id == id);
    return (channel) ? channel.name : "";
  }

  private getChannelId(name: string) {
    let channel: INotificationChannel = this.channels.find(s => s.name == name);
    return (channel) ? channel.id : "";
  }
}

